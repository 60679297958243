<template>
  <Table
    :columns="columns"
    :isAllChecked="isAllSelected"
    class="!max-h-[85vh] mt-4 flex-1"
    @check="selectAll({ isSelected: !isAllSelected })"
  >
    <tr class="sticky top-15 z-1 bg-primary-50 border-b border-neutral-80">
      <TableCell></TableCell>
      <TableCell>
        <InputField
          :placeholder="$t('shared.label.search')"
          prefix-icon="fa-magnifying-glass"
          @inputChange="({ value }) => onFilter(value, 'source')"
        />
      </TableCell>
      <TableCell>
        <InputField
          :placeholder="$t('shared.label.search')"
          prefix-icon="fa-magnifying-glass"
          @inputChange="({ value }) => onFilter(value, 'target')"
        />
      </TableCell>
      <TableCell>
        <InputField
          :placeholder="$t('shared.label.search')"
          prefix-icon="fa-magnifying-glass"
          @inputChange="({ value }) => onFilter(value, 'synthetic_target')"
        />
      </TableCell>
      <TableCell v-if="status" overflowVisible>
        <Select>
          <SelectSelectionCustom
            data-cy="status_select"
            dropdownSize="max"
            :placeholder="$t('customer.lang_assets.entry_type.title')"
            :value="getStatusValue"
          >
            <SelectOptionCustom
              :value="null"
              :selected="!tableFilter.status"
              @inputChange="onFilter($event, 'status')"
            >
              <span>{{ $t('customer.lang_assets.content_type.all') }}</span>
            </SelectOptionCustom>
            <SelectOptionCustom
              value="disabled"
              :selected="tableFilter.status === 'disabled'"
              @inputChange="onFilter($event, 'status')"
            >
              <span>{{
                $t('customer.training_data.ai_training.disabled')
              }}</span>
            </SelectOptionCustom>
            <SelectOptionCustom
              value="enabled"
              :selected="tableFilter.status === 'enabled'"
              @inputChange="onFilter($event, 'status')"
            >
              <span>{{
                $t('customer.training_data.ai_training.enabled')
              }}</span>
            </SelectOptionCustom>
          </SelectSelectionCustom>
        </Select>
      </TableCell>
      <TableCell overflowVisible :tooltip="getSourceTypeValue">
        <Select>
          <SelectSelectionCustom
            data-cy="source_type_select"
            dropdownSize="max"
            :placeholder="$t('customer.lang_assets.entry_type.title')"
            :value="getSourceTypeValue"
          >
            <SelectOptionCustom
              :value="null"
              :selected="!tableFilter.source_type"
              @inputChange="onFilter($event, 'source_type')"
            >
              <span>{{ $t('customer.lang_assets.content_type.all') }}</span>
            </SelectOptionCustom>
            <SelectOptionCustom
              value="human"
              :selected="tableFilter.source_type === 'human'"
              @inputChange="onFilter($event, 'source_type')"
            >
              <span>{{ $t('customer.lang_assets.content_type.human') }}</span>
            </SelectOptionCustom>
            <SelectOptionCustom
              value="synthetic"
              :selected="tableFilter.source_type === 'synthetic'"
              @inputChange="onFilter($event, 'source_type')"
            >
              <span>{{
                $t('customer.lang_assets.content_type.synthetic')
              }}</span>
            </SelectOptionCustom>
          </SelectSelectionCustom>
        </Select>
      </TableCell>
      <TableCell v-if="aiTraining" overflowVisible :tooltip="getUsageValue">
        <Select>
          <SelectSelectionCustom
            data-cy="usage_select"
            dropdownSize="max"
            :placeholder="$t('customer.lang_assets.entry_type.title')"
            :value="getUsageValue"
          >
            <SelectOptionCustom
              :value="null"
              :selected="!tableFilter.usage"
              @inputChange="onFilter($event, 'usage')"
            >
              <span>{{ $t('customer.lang_assets.content_type.all') }}</span>
            </SelectOptionCustom>
            <SelectOptionCustom
              value="global"
              :selected="tableFilter.usage === 'global'"
              @inputChange="onFilter($event, 'usage')"
            >
              <span>{{ $t('customer.training_data.ai_training.global') }}</span>
            </SelectOptionCustom>
            <SelectOptionCustom
              value="team"
              :selected="tableFilter.usage === currentAccountId"
              @inputChange="onFilter($event, 'usage')"
            >
              <span>{{ capitalizeFirstLetter(currentAccountId) }}</span>
            </SelectOptionCustom>
          </SelectSelectionCustom>
        </Select>
      </TableCell>
      <TableCell v-if="actions"></TableCell>
    </tr>
    <template v-if="source && target">
      <template v-if="aiTraining">
        <tr class="border-b border-neutral-80">
          <TableCell></TableCell>
          <TableCell :colspan="`${columns.length - 1}`">
            <BtnSecondary
              outline
              prefix="fa-plus-square"
              :disabled="addingCustomData"
              :title="$t('customer.training_data.ai_training.add_custom_data')"
              @click="setAddingCustomData(true)"
            />
          </TableCell>
        </tr>
        <TrainingTableRow
          v-if="addingCustomData"
          :source="source"
          :target="target"
          aiTraining
          customData
          @update="handleSave"
          @delete="setAddingCustomData(false)"
        />
      </template>
      <slot />
    </template>
    <tr v-else>
      <TableCell></TableCell>
      <TableCell :colspan="`${columns.length - 1}`" class="!py-10">
        <TextTiny lightNeutral>
          {{ $t('customer.training_data.tm_training.table_info') }}
        </TextTiny>
      </TableCell>
    </tr>
  </Table>
</template>
<script setup>
import TrainingTableRow from '@/components/languageAssets/trainingData/TrainingTableRow'
import BtnSecondary from '@/components/shared/btn/BtnSecondary'
import TextTiny from '@/components/shared/font/text/TextTiny'
import InputField from '@/components/shared/input/InputField'
import Select from '@/components/shared/select/Select'
import SelectOptionCustom from '@/components/shared/select/SelectOptionCustom'
import SelectSelectionCustom from '@/components/shared/select/SelectSelectionCustom'
import Table from '@/components/shared/table/Table'
import TableCell from '@/components/shared/table/TableCell'
import { capitalize, capitalizeFirstLetter } from '@/helpers/format'
import i18n from '@/i18n'
import _ from 'underscore'
import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const $t = i18n.t

const emit = defineEmits(['filter'])
const props = defineProps({
  source: String,
  target: String,
  aiTraining: Boolean,
  actions: {
    type: Boolean,
    default: true
  },
  status: {
    type: Boolean,
    default: true
  },
  isAllSelected: Boolean,
  selectAll: Function
})

const columns = ref([
  {
    field: 'checkbox',
    label: '',
    width: 30
  },
  {
    field: 'source_text',
    label: $t('shared.label.source_text'),
    doubleLabel: capitalize(props.source) || 'empty',
    width: 120
  },
  {
    field: 'target_text',
    label: $t('shared.label.target_text'),
    doubleLabel: capitalize(props.target) || 'empty',
    width: 120
  },
  {
    field: 'corrected_target_text',
    label: $t('shared.label.corrected_target'),
    doubleLabel: capitalize(props.target) || 'empty',
    width: 120
  },
  ...(props.status
    ? [
        {
          field: 'status',
          label: $t('shared.label.status'),
          doubleLabel: 'empty',
          width: 100
        }
      ]
    : []),
  {
    field: 'entry_type',
    label: $t('customer.lang_assets.entry_type.title'),
    doubleLabel: 'empty',
    width: 60
  },
  ...(props.aiTraining
    ? [
        {
          field: 'usage',
          label: $t('customer.lang_assets.segment.usage'),
          doubleLabel: 'empty',
          width: 60
        }
      ]
    : []),
  ...(props.actions
    ? [
        {
          field: 'actions',
          label: $t('shared.label.actions'),
          doubleLabel: 'empty',
          width: 40
        }
      ]
    : [])
])

const tableFilter = ref({
  source: '',
  target: '',
  synthetic_target: '',
  status: null,
  source_type: null,
  usage: null
})

const addingCustomData = computed(
  () => store.state.trainingData.addingCustomData
)
const currentAccountId = computed(
  () => store.getters['workspace/currentAccountId']
)

const getStatusValue = computed(() =>
  !tableFilter.value.status
    ? $t('customer.lang_assets.content_type.all')
    : $t(`customer.training_data.ai_training.${tableFilter.value.status}`)
)

const getSourceTypeValue = computed(() =>
  !tableFilter.value.source_type
    ? $t('customer.lang_assets.content_type.all')
    : $t(`customer.lang_assets.content_type.${tableFilter.value.source_type}`)
)

const getUsageValue = computed(() => {
  switch (tableFilter.value.usage) {
    case null:
      return $t('customer.lang_assets.content_type.all')
    case 'global':
      return $t('customer.training_data.ai_training.global')
    default:
      return capitalizeFirstLetter(currentAccountId.value)
  }
})

const createAiData = (payload) =>
  store.dispatch('trainingData/createAiData', payload)
const setAddingCustomData = (payload) =>
  store.commit('trainingData/setAddingCustomData', payload)

const onFilter = _.debounce(function (value, type) {
  tableFilter.value[type] = value
  emit('filter', { value: tableFilter.value[type], type })
}, 300)

watch(
  () => ({
    source: props.source,
    target: props.target
  }),
  (newValues) => {
    if (!newValues.source || !newValues.target) return
    columns.value[1].doubleLabel = capitalize(newValues.source)
    columns.value[2].doubleLabel = capitalize(newValues.target)
    columns.value[3].doubleLabel = capitalize(newValues.target)
  }
)

function handleSave(data) {
  createAiData(data)
}
</script>
