<template>
  <tr class="border-b border-neutral-80">
    <TableCell>
      <CheckboxBtn
        v-if="!customData"
        :data-cy="`select_${index}`"
        class="!mr-0 mt-1"
        :is-active="isSelected(segment.id)"
        @click.prevent.stop="checkboxClick(segment.id)"
      />
    </TableCell>
    <TableCell>
      <Textarea
        data-cy="source_textarea"
        white
        :disabled="!aiTraining"
        :no-area="!aiTraining"
        :rtl="isRightToLeftLanguage(source)"
        :error="!textChange.source && !isSourceEdited"
        :warning="isSourceEdited"
        :value="textChange.source"
        @inputChange="onTextValue($event, 'source')"
        @onBlur="onChange"
      />
    </TableCell>
    <TableCell>
      <Textarea
        data-cy="target_textarea"
        white
        :disabled="disableTarget"
        :no-area="disableTarget"
        :rtl="isRightToLeftLanguage(target)"
        :error="!textChange.target && !isTargetEdited"
        :warning="isTargetEdited"
        :value="textChange.target"
        @inputChange="onTextValue($event, 'target')"
        @onBlur="onChange"
      />
    </TableCell>
    <TableCell>
      <Textarea
        data-cy="corrected_textarea"
        white
        :disabled="disableCorrected"
        :no-area="disableCorrected"
        :no-resize="disableCorrected && !textChange.corrected"
        :rtl="isRightToLeftLanguage(target)"
        :error="!textChange.corrected && !isCorrectedEdited"
        :warning="isCorrectedEdited"
        :value="textChange.corrected"
        @inputChange="onTextValue($event, 'corrected')"
        @onBlur="onChange"
      />
    </TableCell>
    <TableCell v-if="status" overflowVisible>
      <Select>
        <SelectSelectionCustom
          :data-cy="`status_select_${index}`"
          sm
          dropdownSize="max"
          :theme="
            statusValue === $t('customer.training_data.ai_training.enabled')
              ? 'success'
              : 'error'
          "
          :disabled="customData"
          :value="statusValue"
        >
          <SelectOptionCustom
            value="enabled"
            :selected="props.segment?.attributes?.status === 'enabled'"
            @inputChange="onStatus"
          >
            <span>{{ $t('customer.training_data.ai_training.enabled') }}</span>
          </SelectOptionCustom>
          <SelectOptionCustom
            value="disabled"
            :selected="
              props.segment?.attributes?.status === 'disabled' ||
              !props.segment?.attributes?.status
            "
            @inputChange="onStatus"
          >
            <span>{{ $t('customer.training_data.ai_training.disabled') }}</span>
          </SelectOptionCustom>
        </SelectSelectionCustom>
      </Select>
    </TableCell>
    <TableCell :tooltip="sourceType">
      <TextSmall medium lightNeutral>
        {{ sourceType }}
      </TextSmall>
    </TableCell>
    <TableCell
      v-if="aiTraining"
      :overflowVisible="(isAdmin || isTeamAdmin) && !customData"
    >
      <Select v-if="(isAdmin || isTeamAdmin) && !customData">
        <SelectSelectionCustom
          :data-cy="`usage_select_${index}`"
          sm
          dropdownSize="max"
          theme="gray"
          :value="capitalize(getUsageValue)"
        >
          <SelectOptionCustom
            value="global"
            :selected="usage === 'global'"
            @inputChange="onUsageSelect"
          >
            <span>{{
              capitalize($t('customer.training_data.ai_training.global'))
            }}</span>
          </SelectOptionCustom>
          <SelectOptionCustom
            value="team"
            :selected="usage === 'team'"
            @inputChange="onUsageSelect"
          >
            <span>{{ capitalize(currentAccountId) }}</span>
          </SelectOptionCustom>
        </SelectSelectionCustom>
      </Select>
      <TextSmall v-else medium lightNeutral>
        {{ capitalize(getUsageValue) }}
      </TextSmall>
    </TableCell>
    <TableCell v-if="actions" overflowVisible>
      <div class="text-center">
        <BtnSecondary neutral outline @click="handleDelete">
          <template #iconStart>
            <font-awesome-icon icon="trash" />
          </template>
        </BtnSecondary>
      </div>
      <ModalConfirmDeletion
        :show="showDeleteModal"
        :loading="modalLoading"
        @close="showDeleteModal = false"
        @delete="emit('delete', segment)"
      >
        <TextSmall lightNeutral bold class="mb-2">{{
          $t('customer.lang_assets.actions_modal.delete', {
            assetTypeSmall: $t('shared.label.segment')
          })
        }}</TextSmall>
        <TextTiny lightNeutral>{{
          $t('customer.training_data.ai_training.delete.confirm')
        }}</TextTiny>
      </ModalConfirmDeletion>
    </TableCell>
  </tr>
</template>
<script setup>
import BtnSecondary from '@/components/shared/btn/BtnSecondary'
import CheckboxBtn from '@/components/shared/checkbox/CheckboxBtn'
import TextSmall from '@/components/shared/font/text/TextSmall'
import TextTiny from '@/components/shared/font/text/TextTiny'
import ModalConfirmDeletion from '@/components/shared/modals/ModalConfirmDeletion'
import Select from '@/components/shared/select/Select'
import SelectOptionCustom from '@/components/shared/select/SelectOptionCustom'
import SelectSelectionCustom from '@/components/shared/select/SelectSelectionCustom'
import TableCell from '@/components/shared/table/TableCell'
import Textarea from '@/components/shared/textarea/Textarea'
import { isRightToLeftLanguage } from '@/helpers/editor'
import { capitalize } from '@/helpers/format'
import i18n from '@/i18n'
import { computed, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const $t = i18n.t

const emit = defineEmits(['update', 'delete'])
const props = defineProps({
  index: {
    type: String,
    default: 'custom'
  },
  segment: Object,
  selectedSegments: {
    type: Array,
    default: []
  },
  source: String,
  target: String,
  aiTraining: Boolean,
  customData: {
    type: Boolean,
    default: false
  },
  actions: {
    type: Boolean,
    default: true
  },
  status: {
    type: Boolean,
    default: true
  },
  disableTarget: {
    type: Boolean,
    default: false
  },
  disableCorrected: {
    type: Boolean,
    default: false
  },
  select: Function,
  deselect: Function
})

const usage = ref('global')
const textChange = ref({
  source: '',
  target: '',
  corrected: ''
})

const showDeleteModal = ref(false)

const isTeamAdmin = computed(() => store.getters['teams/isTeamAdmin'])
const isAdmin = computed(() => store.getters['workspace/isAdmin'])
const modalLoading = computed(() => store.state.modals.modalLoading)
const currentAccountId = computed(
  () => store.getters['workspace/currentAccountId']
)

const isSourceEdited = computed(() => {
  if (props.customData) return textChange.value.source != ''
  const source = props.segment?.attributes?.source || ''
  return textChange.value.source !== source && textChange.value.source != ''
})
const isTargetEdited = computed(() => {
  if (props.customData) return textChange.value.target != ''
  const target = props.segment?.attributes?.target || ''
  return textChange.value.target !== target && textChange.value.target != ''
})
const isCorrectedEdited = computed(() => {
  if (props.customData) return textChange.value.corrected != ''
  const synthetic_target = props.segment?.attributes?.synthetic_target || ''
  return (
    textChange.value.corrected !== synthetic_target &&
    textChange.value.corrected != ''
  )
})

const sourceType = computed(() => {
  const sourceType = props.segment?.attributes?.source_type || 'human'
  return capitalize($t(`customer.lang_assets.content_type.${sourceType}`))
})

const getUsageValue = computed(() => {
  switch (usage.value) {
    case 'global':
      return $t('customer.training_data.ai_training.global')
    default:
      return currentAccountId.value
  }
})

const statusValue = computed(() => {
  if (props.customData) {
    return $t('customer.training_data.ai_training.disabled')
  }
  return $t(
    `customer.training_data.ai_training.${props.segment?.attributes?.status || 'disabled'}`
  )
})

const setIsReviewSaved = (payload) =>
  store.commit('task/setIsReviewSaved', payload)

onMounted(() => {
  setSegment()
})

function setSegment() {
  textChange.value.source = props.segment?.attributes?.source || ''
  textChange.value.target = props.segment?.attributes?.target || ''
  textChange.value.corrected = props.segment?.attributes?.synthetic_target || ''
  usage.value = props.customData
    ? 'team'
    : props?.segment?.attributes?.team?.name
      ? 'team'
      : 'global'
}

function onTextValue(value, type) {
  if (textChange.value[type] === value) return
  setIsReviewSaved(false)
  textChange.value[type] = value
}

function onUsageSelect(value) {
  usage.value = value
  emit('update', {
    segments: [
      {
        id: props.segment.id,
        usage: value
      }
    ]
  })
}

function isSelected(id) {
  return props.selectedSegments.includes(id)
}
function checkboxClick(id) {
  if (isSelected(id)) props.deselect(id)
  else props.select(id)
}

function onStatus(value) {
  const payload = props.aiTraining
    ? {
        segments: [{ id: props.segment.id, status: value, usage: usage.value }]
      }
    : {
        tmId: props.segment.attributes.translation_memory_id,
        segmentId: props.segment.id,
        attributes: { training_status: value }
      }
  emit('update', payload)
}

function onChange() {
  const { source, target, corrected } = textChange.value

  if (source && target && corrected) {
    const hasChanged =
      source !== props.segment?.attributes?.source ||
      target !== props.segment?.attributes?.target ||
      corrected !== props.segment?.attributes?.synthetic_target

    if (hasChanged) {
      const attributes = { source, target, synthetic_target: corrected }
      const payload = {}

      if (!props.customData && !props.aiTraining) {
        payload.tmId = props.segment.attributes.translation_memory_id
        payload.segmentId = props.segment.id
        payload.attributes = {
          ...attributes,
          training_status: props.segment.attributes.status || 'disabled'
        }
      }
      if (props.aiTraining) {
        payload.segments = [
          { id: props.segment.id, ...attributes, usage: usage.value }
        ]
      }
      emit('update', payload)
    }
  }
}

function handleDelete() {
  if (props.customData) {
    emit('delete')
    return
  }
  showDeleteModal.value = true
}

watch(
  () => props.segment,
  () => setSegment()
)
watch(
  () => modalLoading.value,
  (newValue, oldValue) => {
    if (newValue !== oldValue && oldValue) {
      showDeleteModal.value = false
    }
  }
)
</script>
